/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Query.js
 * Date: 2020/8/19 下午12:12
 * Author:qnnp
 */
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {message} from "antd";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Conf from "../Common/Conf";
import StateAssign from "../Common/StateAssign";
import "babel-polyfill";

function Query(props) {
  let {Page} = useParams();
  let history = useHistory();
  let [CodeState, setCodeState] = useState(0)
  let [QueryData1, setQueryData1] = useState({
    name: '',
    no: '',
    authCode: '',
    phone: '',
  })
  let [QueryResult1, setQueryResult1] = useState(<span/>)
  let [TabIndex, setTabIndex] = useState(Page * 1 || 1)
  let [RegisterData, setRegisterData] = useState({
    ND: (
      new Date()
    ).getFullYear(),
  })
  useEffect(() => {
    props.setState({
      title: '信息查询',
      ShowSidebar: Conf().MobileDevice,
    })
  }, [])
  useEffect(() => {
    if (CodeState > 0) {
      setTimeout(() => {
        setCodeState(CodeState - 1)
      }, 1000)
    }
  }, [CodeState])
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' || window.location.hostname !== 'localhost') return;
    if (!QueryData1.name) {
      setQueryData1({
        // name: '测试人2',
        // no: '320800000000000001',
        name: '测试人',
        no: '320800000000000000',
        authCode: '',
        phone: '',
      })
    } else {
      Query()
    }
  }, [QueryData1]);
  const Query = () => {
    if (!QueryData1.name) {
      message.warn('请输入姓名')
      return
    }
    if (!QueryData1.no) {
      message.warn('请输入身份证号')
      return
    }
    message.loading({content: '查询中...', key: 'query'})
    fetch(`//zs.jscj.edu.cn/jscj/admission/query?name=${QueryData1.name}&id_no=${QueryData1.no}`)
      .then(res => res.json())
      .then(res => {
        message.destroy('query')
        if (res.code && res.msg) {
          message.error(res.msg === '未找到数据' ? '未查询到你的录取信息' : res.msg)
          return
        }
        const width = Math.min(window.innerHeight / 1.6 * 0.9, window.innerWidth * 0.9)
        const baseSize = width / 20
        const isAdmission = res.status !== '未录取'
        setQueryResult1(
          <div
            style={{
              width,
              height: width / 10 * 16,
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              overflow: 'visible',
              zIndex: 9999,
              fontSize: baseSize,
              backgroundImage: `url(/images/admission/gold.jpg)`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              fontWeight: '400',
              textAlign: 'left',
              textJustify: 'inter-word',
              wordBreak: 'break-all',
              filter: 'drop-shadow(0 0 6px black)',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                fontSize: baseSize,
                width: '75%',
                lineHeight: '180%'
              }}
            >
              <div style={{paddingBottom: baseSize / 2, fontSize: baseSize * 0.7}}>
                <span style={{
                  color: isAdmission ? 'red' : '#666',
                  fontSize: baseSize,
                  fontWeight: 'bold'
                }}>{res.name}</span> 同学:
              </div>
              <div style={{
                fontSize: baseSize * 0.7,
              }}>
                <div style={{paddingBottom: baseSize / 2.5}}>
                  {isAdmission
                    ? <div>
                      <div>&emsp;&emsp;恭喜你，你已经被我校 <span
                        style={{color: 'red', fontSize: baseSize * 0.8, fontWeight: 'bold'}}
                      >{res.pro}</span> 专业 {res.status}，你的考生号是 {res.no}。待省考试院审核后可在省考试院报名系统中查询正式录取信息。
                      </div>
                    </div>
                    : <div>
                      {/*<div>&emsp;&emsp;很遗憾，你报考我校 <span*/}
                      {/*  style={{color: '#666', fontSize: baseSize * 0.8, fontWeight: 'bold'}}*/}
                      {/*>{res.pro}</span> 专业结果为 {res.status}，你的考生号是 <span*/}
                      {/*  style={{color: isAdmission ? 'red' : '#666'}}>{res.no}</span>。待省考试院审核后可在省考试院报名系统中查询正式录取信息。*/}
                      {/*</div>*/}
                      <div style={{
                        fontSize: 20,
                        lineHeight: '80px',
                      }}>&emsp;&emsp;很遗憾，{res.status}</div>
                    </div>}
                </div>
                <div style={{
                  fontSize: '80%',
                  lineHeight: '150%'
                }}>
                  {Object.keys(res.score).filter(e => e === '总成绩').map((key, index) => {
                    if (key === '总成绩') {
                      return <span
                        key={index}
                        style={{fontSize: 24, fontWeight: 'bold', display: 'inline-block'}}
                      >&emsp;&emsp;{key}: <span
                        style={{color: isAdmission ? 'red' : '#666'}}>{res.score[key]}</span></span>
                    }
                    return <span
                      key={index}
                      style={{display: 'inline-block'}}
                    >&emsp;&emsp;{key}: <span
                      style={{color: isAdmission ? 'red' : '#666'}}>{res.score[key]}</span></span>
                  })}
                </div>
                <div style={{textAlign: 'right'}}>{res.dep}</div>
                <div style={{textAlign: 'right'}}>{moment(res.date).format('YYYY年MM月DD日')}</div>
              </div>
              {/*<div style={{fontSize: baseSize * 0.7, paddingTop: baseSize / 2}}>*/}
              {/*  提示: 可加入已录取新生QQ群:770820646，凭姓名和身份证号后6位验证加入。*/}
              {/*</div>*/}
            </div>
            <div
              style={{
                position: 'absolute',
                top: -baseSize,
                right: -baseSize,
                width: baseSize * 2,
                height: baseSize * 2,
                backgroundColor: 'rgba(255,0,0,0.8)',
                borderRadius: baseSize,
                boxShadow: '0 0 10px -2px #000',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  fontSize: baseSize * 2,
                  fontFamily: 'monospace',
                  color: 'white',
                }}
                onClick={() => setQueryResult1('')}
              >&times;</div>
            </div>
          </div>,
        )
      })
      .catch(err => {
        message.destroy('query')
        message.error(err)
      })
  };
  const [theeeInput, setTheeeInput] = useState();
  return (
    <Grid container item>
      <div
        style={{
          width: '100%',
          margin: '30px 15px',
        }}
      >
        <img src={'/images/admission/gold.jpg'} style={{width: 0, height: 0, opacity: 0}}/>
        <div
          style={{
            width: '100%',
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: '200%',
              transition: 'all 0.5s',
              marginLeft: (
                -100 * (
                  TabIndex - 1
                )
              ) + '%',
            }}
          >
            <div
              style={{
                width: '50%',
                float: 'left',
              }}
            >
            </div>
            <div
              style={{
                width: '50%',
                float: 'left',
              }}
            >
              {/*	统招录取查询*/}
              <div
                style={{
                  maxWidth: '600px',
                  margin: "auto",
                }}
              >
                <Grid
                  container
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: '20px',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      fontSize: '20px',
                      width: '100%',
                      padding: '5px 0',
                      borderBottom: 'solid 1px #eee',
                      marginBottom: '20px',
                    }}
                  >
                    录取查询
                  </div>
                  <TextField
                    size={"small"}
                    variant={"outlined"}
                    label={'姓名'}
                    required
                    style={{
                      width: (
                        Conf().MobileDevice
                      ) ? '70%' : '100%',
                      marginBottom: '15px',
                    }}
                    onChange={e => StateAssign(QueryData1, {name: e.target.value})}
                    InputProps={{ref: setTheeeInput}}
                  />
                  <TextField
                    size={"small"}
                    variant={"outlined"}
                    label={'身份证号'}
                    required
                    style={{
                      width: (
                        Conf().MobileDevice
                      ) ? '70%' : '100%',
                      marginBottom: '15px',
                    }}
                    onChange={e => StateAssign(QueryData1, {no: e.target.value})}
                  />
                  <Button
                    fullWidth size={"small"} variant={"contained"} color={"primary"}
                    style={{
                      width: (
                        Conf().MobileDevice
                      ) ? '70%' : '100%',
                      margin: 'auto',
                    }}
                    onClick={Query}
                  >查询</Button>
                </Grid>
                <Grid style={{padding: "20px 0"}}>
                  {QueryResult1}
                </Grid>

                {/*<Grid*/}
                {/*	container*/}
                {/*	style={{*/}
                {/*		justifyContent: "space-between",*/}
                {/*		marginTop     : '20px'*/}
                {/*	}}*/}
                {/*>*/}
                {/*	<div*/}
                {/*		style={{*/}
                {/*			fontSize    : '20px',*/}
                {/*			width       : '100%',*/}
                {/*			padding     : '5px 0',*/}
                {/*			borderBottom: 'solid 1px #eee',*/}
                {/*			marginBottom: '20px'*/}
                {/*		}}*/}
                {/*	>*/}
                {/*		提前招生*/}
                {/*	</div>*/}
                {/*	*/}
                {/*	<Button*/}
                {/*		style={{*/}
                {/*			margin: "10px 0",*/}
                {/*			width : 'calc(33.33333333% - 10px)'*/}
                {/*		}}*/}
                {/*		color={"primary"}*/}
                {/*		variant={"outlined"}*/}
                {/*		size={"small"}*/}
                {/*		onClick={() => history.push('/TZZkz')}*/}
                {/*	>提前招生准考证打印</Button>*/}
                {/*	<Button*/}
                {/*		style={{*/}
                {/*			margin: "10px 0",*/}
                {/*			width : 'calc(33.33333333% - 10px)'*/}
                {/*		}}*/}
                {/*		color={"primary"}*/}
                {/*		variant={"contained"}*/}
                {/*		size={"small"}*/}
                {/*		onClick={() => history.push('/TZQuery')}*/}
                {/*	>提前招生校测成绩查询</Button>*/}
                {/*	<Button*/}
                {/*		style={{*/}
                {/*			margin: "10px 0",*/}
                {/*			width : 'calc(33.33333333% - 10px)'*/}
                {/*		}}*/}
                {/*		color={"primary"}*/}
                {/*		variant={"contained"}*/}
                {/*		size={"small"}*/}
                {/*		onClick={() => {*/}
                {/*			console.log({theeeInput})*/}
                {/*			message.info('请输入信息直接查询')*/}
                {/*			theeeInput.firstChild.focus()*/}
                {/*		}}*/}
                {/*	>提前招生录取查询</Button>*/}
                {/*</Grid>*/}
                {/*<Grid*/}
                {/*	style={{*/}
                {/*		padding       : "20px 0",*/}
                {/*		display       : 'flex',*/}
                {/*		justifyContent: "space-between",*/}
                {/*		flexWrap      : "wrap"*/}
                {/*	}}*/}
                {/*>*/}
                {/*	<div*/}
                {/*		style={{*/}
                {/*			fontSize    : '20px',*/}
                {/*			width       : '100%',*/}
                {/*			padding     : '5px 0',*/}
                {/*			borderBottom: 'solid 1px #eee',*/}
                {/*			marginBottom: '20px'*/}
                {/*		}}*/}
                {/*	>*/}
                {/*		社会招生*/}
                {/*	</div>*/}
                {/*	<Button*/}
                {/*		style={{*/}
                {/*			margin: "10px 0",*/}
                {/*			width : 'calc(33.33333333% - 10px)'*/}
                {/*		}}*/}
                {/*		color={"primary"}*/}
                {/*		variant={"outlined"}*/}
                {/*		size={"small"}*/}
                {/*		onClick={() => history.push('/SignUpQuery')}*/}
                {/*	>社招考生打印准考证</Button>*/}
                {/*	<Button*/}
                {/*		style={{*/}
                {/*			margin: "10px 0",*/}
                {/*			width : 'calc(33.33333333% - 10px)'*/}
                {/*		}}*/}
                {/*		color={"primary"}*/}
                {/*		variant={"outlined"}*/}
                {/*		size={"small"}*/}
                {/*		disabled*/}
                {/*	>社招考生校测成绩查询</Button>*/}
                {/*	<Button*/}
                {/*		style={{*/}
                {/*			margin: "10px 0",*/}
                {/*			width : 'calc(33.33333333% - 10px)'*/}
                {/*		}}*/}
                {/*		color={"primary"}*/}
                {/*		variant={"contained"}*/}
                {/*		size={"small"}*/}
                {/*		onClick={() => history.push('/SignUpCat')}*/}
                {/*	>社招考生录取查询</Button>*/}
                {/*</Grid>*/}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default Query;
